<template>
  <div>
    <b-card
    no-body
    class="mb-2"
  >
      <b-container fluid>
          <validation-observer
              ref="formFilters"
          >
              <!-- Form -->
              <b-row class="p-1">
                  <b-col cols="12" md="4">                                
                    <validation-provider
                                    #default="{ errors }"
                                    name="farmacia"
                                    rules="required"
                                >
                                <b-form-group
                                    label="Farmacia"
                                    label-for="customer"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <v-select 
                                        v-model="filter.customer" 
                                        :options="customers"
                                        clearable
                                        trim
                                        :reduce="val => val.id"
                                        :state="errors.length > 0 ? false:null"
                                        label="name"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                  </b-col>
                  <b-col cols="12" md="2">
                      <validation-provider
                      #default="{ errors }"
                      name="estado"
                      >
                      <b-form-group
                          label="Estado"
                          label-for="status"
                          :class="errors.length > 0 ? 'is-invalid':null"
                      >
                          <v-select 
                              v-model="filter.status" 
                              :options="[{id: 0, name: 'Inactivo'},{id: 1, name: 'Activo'}]"
                              clearable
                              trim
                              :reduce="val => val.id"
                              :state="errors.length > 0 ? false:null"
                              label="name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                      </validation-provider>
                  </b-col>
                  <b-col cols="12" md="2" >
                      <validation-provider
                      #default="{ errors }"
                      name="desde"
                      rules="required"
                      >
                      <b-form-group
                          label="Desde"
                          label-for="begins"
                          :class="errors.length > 0 ? 'is-invalid':null"
                      >
                          <b-form-input
                              id="begins"
                              trim
                              v-model="filter.begins"
                              type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                      </validation-provider>
                  </b-col>
                  <b-col cols="12" md="2" >
                      <validation-provider
                      #default="{ errors }"
                      name="hasta"
                      rules="required"
                      >
                      <b-form-group
                          label="Hasta"
                          label-for="ends"
                          :class="errors.length > 0 ? 'is-invalid':null"
                      >
                          <b-form-input
                              id="ends"
                              trim
                              v-model="filter.ends"
                              type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                      </validation-provider>
                  </b-col>
                  <b-col cols="12" md="2">
                      <b-button variant="primary" class="btn-icon btn-block mt-2" @click="generateReport">
                          <feather-icon icon="DownloadCloudIcon" /> Descargar
                      </b-button>
                  </b-col>
              </b-row>
              <!-- Fin Form -->
              
          </validation-observer>
      </b-container>
  </b-card>
  </div>
</template>

<script>
import { 
    VBToggle
} from 'bootstrap-vue';
import vSelect from 'vue-select'
import { required } from '@validations'
import es from 'vee-validate/dist/locale/es';
import { ValidationProvider, ValidationObserver, localize, localeChanged } from 'vee-validate'

export default {
  name: 'ReportsStock',
  directives: {
      'b-toggle': VBToggle,
  },
  components: { 
    vSelect, 
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      customers: [],
      filter: {
        customer: null,
        status: null,
      },
    }
  },
  created() {
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
        await this.$http.get('/users/get-customers')
        .then((response) => {
            this.customers = response.data
        })
    },
    async generateReport(){
      localize('es', es);
      this.$refs.formFilters.validate().then(success => {
        if (success) {
          this.$http.get('/reports/stock', {responseType: 'blob', params: this.filter })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'reporte_inventario.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch(error => {
            console.error(error)
          })
        }
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
