var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"formFilters"},[_c('b-row',{staticClass:"p-1"},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"farmacia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Farmacia","label-for":"customer"}},[_c('v-select',{attrs:{"options":_vm.customers,"clearable":"","trim":"","reduce":function (val) { return val.id; },"state":errors.length > 0 ? false:null,"label":"name"},model:{value:(_vm.filter.customer),callback:function ($$v) {_vm.$set(_vm.filter, "customer", $$v)},expression:"filter.customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Estado","label-for":"status"}},[_c('v-select',{attrs:{"options":[{id: 0, name: 'Inactivo'},{id: 1, name: 'Activo'}],"clearable":"","trim":"","reduce":function (val) { return val.id; },"state":errors.length > 0 ? false:null,"label":"name"},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"desde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Desde","label-for":"begins"}},[_c('b-form-input',{attrs:{"id":"begins","trim":"","type":"date"},model:{value:(_vm.filter.begins),callback:function ($$v) {_vm.$set(_vm.filter, "begins", $$v)},expression:"filter.begins"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"hasta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Hasta","label-for":"ends"}},[_c('b-form-input',{attrs:{"id":"ends","trim":"","type":"date"},model:{value:(_vm.filter.ends),callback:function ($$v) {_vm.$set(_vm.filter, "ends", $$v)},expression:"filter.ends"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-button',{staticClass:"btn-icon btn-block mt-2",attrs:{"variant":"primary"},on:{"click":_vm.generateReport}},[_c('feather-icon',{attrs:{"icon":"DownloadCloudIcon"}}),_vm._v(" Descargar ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }